<template>
  <div class="ViewerPage">
    <!-- Modal  start-->
      <div>
        <CRow>
          <CCol>
            <h5 class="subhead-modal">
              Creator detail
            </h5>
          </CCol>
          <CCol>
            <div class="subhead-modal-action">
              <CLink
                class="card-header-action btn-minimize"
                @click="formCollapsed = !formCollapsed"
              >
                <CIcon name="cil-chevron-bottom" v-if="formCollapsed" />
                <CIcon name="cil-chevron-top" v-else />
              </CLink>
            </div>
          </CCol>
        </CRow>
        <CCollapse :show="formCollapsed">
          <CRow>
            <CCol md="6">
              <CInput
                label="Creator"
                horizontal
                readonly="readonly"
                v-model="Creator"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Create Date"
                horizontal
                readonly="readonly"
                v-model="CreateDate"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <CInput
                label="E-mail"
                horizontal
                readonly="readonly"
                v-model="Email"
              />
            </CCol>
            <CCol md="6">
              <CInput
                label="Business unit"
                horizontal
                readonly="readonly"
                v-model="CreatorBU"
              />
            </CCol>
          </CRow>
        </CCollapse>

        <hr />
      </div>

      <CRow>
        <h5 class="subhead-modal pl-3">
          Market / Customer
        </h5>
      </CRow>

      <CRow>
        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">For Which Industry</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="DataObj.ConceptCusIndustry"
              :value.sync="DataObj.ConceptCusIndustry"
              :options="IndustryOptions"
              placeholder="-- Select --"
              @input="IndustryOnChange($event)"
              :disabled="disabled"
            />
          </div>
        </CCol>

        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">Segment</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="DataObj.ConceptCusSegment"
              :value.sync="DataObj.ConceptCusSegment"
              :options="SegmentOptions"
              placeholder="-- Select --"
              @input="SegmentOnChange($event)"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6" class="form-group form-row">
          <div class="col-md-3">
            <label class="">Sub Segment</label>
          </div>
          <div class="col-md-9 pr-0">
            <v-select
              v-model="DataObj.ConceptCusSubSegment"
              :value.sync="DataObj.ConceptCusSubSegment"
              :options="SubSegmentOptions"
              placeholder="-- Select --"
              :disabled="disabled"
            />
          </div>
        </CCol>
      </CRow>

      <hr />
      <br />

      <div>
        <CRow>
          <h5 class="subhead-modal pl-3">
            Link from which ideas?
          </h5>
          <!-- {{ideaSelected}} -->
        </CRow>

        <CRow>
          <CCol md="6 form-group form-row">
            <div class="col-md-3">
              <label class="">Idea No.</label>
            </div>
            <div class="col-md-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <CButton color="secondary" size="sm" @click="searchIdea()" v-if="viewmode !== 'view'">
                    <CIcon name="cil-magnifying-glass" />Search
                  </CButton>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol md="12">
            <CDataTable
              :items="ideaSelected"
              :fields="ideaFieldsSelected"
              sorter
            >
              <template #Insightdetail="{item}">
                <td>
                  <table>
                    <tr
                      v-for="insight in item.InsightLinks"
                      :key="insight.InsightNo"
                    >
                      <td>{{ insight.InsightNo }}</td>
                      <td>{{ insight.InsightDetail }}</td>
                    </tr>
                  </table>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </div>
      <hr />

            <div>
        <CRow>
          <CCol md="12">
            <CRow>
              <h5 class="subhead-modal pl-3">
                Ideation event detail
              </h5>
            </CRow>
            <CRow>
              <CCol class="form-group form-row">
                <div class="col-md-3">
                  <label class="">Industry</label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    v-model="IdeaIndustry"
                    :value.sync="IdeaIndustry"
                    :options="IndustryOptions"
                    placeholder="-- Select --"
                    disabled
                  />
                </div>
              </CCol>

              <CCol class="form-group form-row">
                <div class="col-md-3">
                  <label class="">Group</label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    v-model="IdeaGroup"
                    :value.sync="IdeaGroup"
                    :options="groupOptions"
                    placeholder="-- Select --"
                    disabled
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="form-group form-row" md="6">
                <div class="col-md-3">
                  <label class="">Event date</label>
                </div>
                <div class="col-md-9 pr-0">
                  <DatePicker
                class="custom-input-date"
                v-model="IdeaEventDate"
                :first-day-of-week="2"
                :masks="{ input: ['YYYY/MM/DD'], data: ['YYYY/MM/DD'] }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="bg-white border px-2 py-1 rounded"
                    :value="inputValue"
                    v-on="inputEvents"
                    placeholder="YYYY/MM/DD"
                    readonly
                    disabled
                  />
                </template>
              </DatePicker>
                </div>
              </CCol>

              <CCol class="form-group form-row">
                <div class="col-md-3">
                  <label class="">Segment</label>
                </div>
                <div class="col-md-9 pr-0">
                  <v-select
                    v-model="IdeaSegment"
                    :value.sync="IdeaSegment"
                    :options="SegmentOptions"
                    placeholder="-- Select --"
                    disabled
                  />
                </div>
              </CCol>

            </CRow>
          </CCol>

          
        </CRow>
        <hr />
      </div>
      <br />

      <CRow>
        <h5 class="subhead-modal pl-3">
          Concept detail
        </h5>
      </CRow>
      <CRow>
        <CCol md="6">
          <CTextarea
            label="IDEA"
            rows="3 "
            horizontal
            placeholder="What is your idea ?"
            DataObj.ConceptCusSegment
            v-model="DataObj.ConceptIdea"
          />
        </CCol>
        <CCol md="6">
          <CTextarea
            label="To solve"
            rows="3 "
            horizontal
            placeholder="What is problem to be solved ?"
            v-model="DataObj.ConceptToSolve"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol md="6">
          <CTextarea
            label="Whom"
            rows="3 "
            horizontal
            placeholder="who want this problem solved ?"
            v-model="DataObj.ConceptWhom"
          />
        </CCol>
        <CCol md="6">
          <CTextarea
            label="Current solutions"
            rows="3 "
            horizontal
            v-model="DataObj.ConceptCurrentSolution"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CTextarea
            label="Detail of product & services"
            placeholder="(Material, Design, Process)"
            rows="3 "
            horizontal
            v-model="DataObj.ConceptDetailOfProd"
          />
        </CCol>
        <CCol md="6">
          <CInputFile
            multiple
            custom
            label="Attach files"
            horizontal
            v-on:change="fileChange($event)"
            disabled
            readonly
          />
          <div
            class="cpl-10"
            v-for="file in DataObj.fileExists"
            :key="file.AttachmentID"
          >
            <div v-if="!file.IsDelete">
              <CLink
                class="text-primary"
                @click="OpenFile(file.AttachmentName)"
                >{{ file.AttachmentName }}</CLink
              >

              <CButton
                class="btn-sm"
                @click="ConfirmDeleteFile(file)"
                v-if="viewmode !== 'view'"
                color="danger"
                >Delete</CButton
              >
            </div>
          </div>
          <div class="cpl-10" v-for="file in DataObj.files" :key="file.name">
            {{ file.name }}
          </div>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="6">
          <CTextarea
            label="Key resource needed"
            rows="3 "
            horizontal
            v-model="DataObj.ConceptNeed"
          />
        </CCol>
        <CCol md="6">
          <CInput
            label="Concept name"
            horizontal
            v-model="DataObj.ConceptName"
          />
        </CCol>
      </CRow>

      <hr />
      
      <CRow>
        <h5 class="subhead-modal pl-3">
          Concept evaluation
        </h5>
      </CRow>

      <br />

      <CRow>
        <h6 class="subhead-modal pl-3">
          Market Attractiveness
        </h6>
      </CRow>

      <table class="table table-hover">
        <thead>
          <tr width="100%">
            <th>No</th>
            <th width="40%">Criteria</th>
            <th class="text-center">Weight</th>
            <th width="20%" class="text-center">Score</th>
            <th width="20%">Assumption</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in marketAttractiveness" :key="obj.ScoreID">
            <td>
              {{ key + 1 }}
            </td>
            <td>
              {{ obj.ScoreCriteria }}
            </td>
            <td class="text-center">
              {{ obj.ScoreWeight }}
            </td>
            <td class="text-center">
              <!-- {{obj.ScoreSelect}} -->
              <CButton
                v-for="(item, itemkey) in obj.ScoreDetail"
                :key="item.ScorePointID"
                :color="
                  itemkey == 0 ? 'light' : itemkey == 1 ? 'secondary' : 'dark'
                "
                v-c-tooltip.hover="item.ScorePointDesc"
                size="lg"
                :groupname="'idgroup' + item.ScoreID"
                @click="
                  clickScore('idgroup' + item.ScoreID, item.ScorePoint, obj)
                "
                :class="
                  item.ScorePoint === obj.ScoreSelect ? 'btnSelectedScore' : ''
                "
              >
                {{ item.ScorePoint }}
              </CButton>
            </td>
            <td><CTextarea rows="3 " v-model="obj.Assumption" /></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">
              Auto-Cal Total Sum : {{ totalMarketAttractiveness }}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>

      <CRow>
        <h6 class="subhead-modal pl-3">
          Project Relatedness
        </h6>
      </CRow>

      <table class="table table-hover">
        <thead>
          <tr width="100%">
            <th>No</th>
            <th width="40%">Criteria</th>
            <th class="text-center">Weight</th>
            <th width="20%" class="text-center">Score</th>
            <th width="20%">Assumption</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj, key) in projectRelatedness" :key="obj.ScoreID">
            <td>
              {{ key + 1 }}
            </td>
            <td>
              {{ obj.ScoreCriteria }}
            </td>
            <td class="text-center">
              {{ obj.ScoreWeight }}
            </td>
            <td class="text-center">
              <CButton
                v-for="(item, itemkey) in obj.ScoreDetail"
                :key="item.ScorePointID"
                :color="
                  itemkey == 0 ? 'light' : itemkey == 1 ? 'secondary' : 'dark'
                "
                v-c-tooltip.hover="item.ScorePointDesc"
                size="lg"
                :groupname="'idgroup' + item.ScoreID"
                @click="
                  clickScore('idgroup' + item.ScoreID, item.ScorePoint, obj)
                "
                :class="
                  item.ScorePoint === obj.ScoreSelect ? 'btnSelectedScore' : ''
                "
              >
                {{ item.ScorePoint }}
              </CButton>
            </td>
            <td><CTextarea rows="3 " v-model="obj.Assumption" /></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-center">
              Auto-Cal Total Sum : {{ totalProjectRelatedness }}
            </td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <CRow>
        <CCol md="3"></CCol>
        <CCol md="9">
          <!-- <ScatterChart
            :chartData.sync="mydata"
            @datacollection-updated="mydata = $event"
            :options.sync="myOption"
          ></ScatterChart> -->

          <vue-apex-charts
            type="scatter"
            :options="chartOptions"
            :series="chartSeries"
            width="500"
            height="500"
          ></vue-apex-charts>
        </CCol>
      </CRow>

    <!-- <ScoreTable /> -->
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { DatePicker } from "v-calendar";
import VueApexCharts from "vue-apexcharts";
import store from "../../store";
import axios from "axios";
import { uuid } from "vue-uuid";
import RejectModal from "../RejectModal";

var URL_GetByID = store.getters.URL + "/api/IdeationConcept/getbyid";
var URL_Insert = store.getters.URL + "/api/IdeationConcept/insert";
var URL_Update = store.getters.URL + "/api/IdeationConcept/update";
var URL_Delete = store.getters.URL + "/api/IdeationConcept/delete";
var URL_Upload = store.getters.URL + "/api/IdeationConcept/uploadfile";
var URL_GetFilesByDoc = store.getters.URL + "/api/IdeationAttachment/getbydocid";
var URL_OpenFile = store.getters.URL + "/api/IdeationAttachment/openfile";
var URL_GetGroup = store.getters.URL + "/api/MasterGroup/getall";

const today = new Date();

const ideaFields = [
  {
    key: "selectbox",
    label: "Select",
    _style: "width:1%",
    sorter: false,
    filter: false
  },
  { key: "IdeaNo", label: "Idea No." },
  { key: "IdeaIndustry", label: "Industry" },
  { key: "IdeaDetail", label: "Idea detail" },
  { key: "IdeaCreator", label: "Creator" },
  { key: "IdeaCreateDate", label: "Create Date" },
  { key: "IdeaStatus", label: "Status" }
];

const ideaFieldsSelected = [
  { key: "IdeaNo", label: "Idea No." },
  { key: "IdeaDetail", label: "Idea detail" },
  {
    key: "Insightdetail",
    label: "Insight No. and Insight detail",
    sorter: false,
    filter: false
  }
];

export default {
  name: "ConceptModal",
  components: {
    vSelect,
    DatePicker,
    VueApexCharts,
    RejectModal
  },
  data() {
    return {
      SelectInsightModal: false,
      isviewDocumentStatus: true,
      formCollapsed: true,

      showInsightSelectModal: false,

      showIdeaSelectModal: false,
      ideaFields,
      ideaData: [],
      ideaFieldsSelected,

      marketAttractiveness: [],
      projectRelatedness: [],
      TempDataEdit: [],

      ideaDate: "",

      MarketScoreGroup: "Market",
      ProjectScoreGroup: "Project",
      CreateUser: "",
      Creator: "",
      CreateDate: "",
      Email: "",
      CreatorBU: "",      

      DataObj: this.NewObj(null),

      Datadate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),

      InsightList: [],
      IndustryOptions: [{ value: null, label: "--Select--" }],
      SegmentList: [],
      SegmentOptions: [{ value: null, label: "--Select--" }],
      SubSegmentList: [],
      SubSegmentOptions: [{ value: null, label: "--Select--" }],
      groupOptions: [{ value: "", label: "--Select--" }],
      IdeaGroup : "",
      IdeaIndustry : "",
      IdeaEventDate : "",
      IdeaSegment : "",

      // userprofile: JSON.parse(localStorage.getItem("userprofile")),
      // userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),

      chartOptions: {
        chart: {
          type: "scatter",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          tickAmount: 10,
          min: 0,
          max: 100,
          labels: {
            align: "center"
          },
          title: {
            text: "Project relatedness"
          }
        },
        yaxis: {
          tickAmount: 10,
          min: 0,
          max: 100,
          labels: {
            align: "center"
          },
          title: {
            text: "Market attractiveness"
          }
        },
        annotations: {
          yaxis: [
            {
              y: 60,
              borderColor: "red"
            }
          ],
          xaxis: [
            {
              x: 60,
              borderColor: "red"
            }
          ]
        }
      },
      chartSeries: [
        {
          name: "Concept",
          data: [[this.totalMarketAttractiveness, this.totalProjectRelatedness]]
        }
      ],
      confirmSubmitConceptModal: false,
      submitObj: [],
      k2Obj: [],
      confirmApproveConceptModal : false,
      conceptProcessID:"",
      disabled: false,
      conceptStatus: "",
      showRejectModal: false,
      rejectModel: {
        DocumentNo: "",
        ProcessID: "",
        RejectLevel: "",
        RejectUser: "",
        RejectComment: ""
      },
      conceptNo: ""
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    
    //TypeConcept: String,
    
    viewmode: {
      type: String,
      default: "view",
      required: false
    },
    
    ideaDataSelected: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  mounted() {

    this.mydata = {
      datasets: [
        {
          label: "Concept name",
          fill: false,
          borderColor: "#f87979",
          backgroundColor: "#f87979",
          data: [
            {
              x: 40,
              y: 80
            }
          ]
        }
      ]
    };
    this.myOption = {
      responsive: true,
      maintainAspectRatio: true,
      datasets: {
        horizontalBar: {
          backgroundColor: "rgb(255, 99, 132, 0.5)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1,
          barPercentage: 1,
          categoryPercentage: 1,
          yAxisID: "bary",
          xAxisID: "barx"
        }
      },
      scales: {
        xAxes: [
          {
            type: "linear",
            position: "bottom",
            ticks: {
              min: 0, //minimum tick
              max: 100, //maximum tick
              stepSize: 10,
              reverse: false,
              beginAtZero: true
            }
          },
          {
            id: "barx",
            type: "category",
            stacked: true,
            labels: ["Project related"],
            offset: true
          }
        ],
        yAxes: [
          {
            type: "linear",
            position: "bottom",
            ticks: {
              min: 0, //minimum tick
              max: 100, //maximum tick
              stepSize: 10,
              reverse: false,
              beginAtZero: true
            }
          },
          {
            id: "bary",
            type: "category",
            stacked: true,
            labels: ["Market attractiveness"],
            offset: true
          }
        ]
      }
    };

    setTimeout(
      function() {
        this.LoadIndustry();
        this.LoadSegment();
        this.LoadSubSegment();
        this.LoadIdea();
        this.GetGroup();
        let DocNo = this.$route.params.DocNo || "";
        //console.log('DocNo = ', DocNo)
        if(DocNo !== ""){
          this.LoadById(DocNo);
        }
      }.bind(this),
      200
    );

    
  },
  methods: {
     SetViewMode(disabled) {
      this.disabled = disabled;

      let elements = document.querySelectorAll('input,textarea,button');
      elements.forEach((element) => {
        if (element.id !== "btnCancel" && element.id !== "btnCloseModal") element.disabled = disabled;
      });
    },
    NewObj(Obj) {
      var NewObj = {
        ConceptNo: Obj === null ? null : Obj.ConceptNo,
        ConceptCreator: Obj === null ? this.Creator : Obj.ConceptCreator,
        ConceptCreateDate:
          Obj === null ? this.CreateDate : Obj.ConceptCreateDate,
        ConceptCreatorEmail:
          Obj === null ? this.Email : Obj.ConceptCreatorEmail,
        ConceptCreatorBU: Obj === null ? this.CreatorBU : Obj.ConceptCreatorBU,
        BU: Obj === null ? this.CreatorBU : Obj.BU,

        ConceptCusIndustry:
          Obj === null
            ? null
            : Obj.ConceptCusIndustry === null
            ? null
            : this.IndustryOptions[
                this.IndustryOptions.findIndex(
                  x =>
                    x.value ===
                    (Obj.ConceptCusIndustry.value == undefined
                      ? Obj.ConceptCusIndustry
                      : Obj.ConceptCusIndustry.value)
                )
              ],
        ConceptCusSegment:
          Obj === null
            ? null
            : Obj.ConceptCusSegment === null
            ? null
            : this.SegmentOptions[
                this.SegmentOptions.findIndex(
                  x =>
                    x.value ===
                    (Obj.ConceptCusSegment.value == undefined
                      ? Obj.ConceptCusSegment
                      : Obj.ConceptCusSegment.value)
                )
              ],
        ConceptCusSubSegment:
          Obj === null
            ? null
            : Obj.ConceptCusSubSegment === null
            ? null
            : this.SubSegmentOptions[
                this.SubSegmentOptions.findIndex(
                  x =>
                    x.value ===
                    (Obj.ConceptCusSubSegment.value == undefined
                      ? Obj.ConceptCusSubSegment
                      : Obj.ConceptCusSubSegment.value)
                )
              ],

        ConceptIdea: Obj === null ? null : Obj.ConceptIdea,
        ConceptToSolve: Obj === null ? null : Obj.ConceptToSolve,
        ConceptWhom: Obj === null ? null : Obj.ConceptWhom,
        ConceptCurrentSolution:
          Obj === null ? null : Obj.ConceptCurrentSolution,
        ConceptDetailOfProd: Obj === null ? null : Obj.ConceptDetailOfProd,
        ConceptAttach: Obj === null ? null : Obj.ConceptAttach,
        ConceptNeed: Obj === null ? null : Obj.ConceptNeed,
        ConceptName: Obj === null ? null : Obj.ConceptName,
        ConceptTotalMarketScore: Obj === null ? 0 : Obj.ConceptTotalMarketScore,
        ConceptTotalProjectScore:
          Obj === null ? 0 : Obj.ConceptTotalProjectScore,

        CreateByUser: Obj === null ? this.CreateUser : Obj.CreateByUser,
        ConceptStatus: Obj === null ? "Draft" : Obj.ConceptStatus,
        // BU: Obj === null ? null : Obj.BU,
        ConceptMarketScoreGroup:
          Obj === null ? this.MarketScoreGroup : Obj.ConceptMarketScoreGroup,
          ConceptProjectScoreGroup:
          Obj === null ? this.ProjectScoreGroup : Obj.ConceptProjectScoreGroup,
        files: Obj === null ? null : Obj.files,
        FlagDelete: false,
        IndustryDesc: Obj === null ? null : Obj.IndustryDesc,
        SegmentDesc: Obj === null ? null : Obj.SegmentDesc,
        fileExists:
          Obj === null ? [] : Obj.fileExists === null ? [] : Obj.fileExists,
        FileNames: [],
        MappingIdea: [],
        MarketAttractiveness: [],
        ProjectRelatedness: []
      };

      //this.conceptStatus = NewObj.ConceptStatus;

      return NewObj;
    },
    LoadById(Id) {
      //console.log("LoadById", Id);
      axios
        .get(URL_GetByID, {
          params: {
            uid: Id
          }
        })
        .then(response => {
          if (response !== null) {
            //console.log("data LoadById", response.data);

            let SegmentOptions = [{ value: null, label: "--Select--" }];

            var SegmentFilter = this.SegmentList.filter(function(obj) {
              return obj.IndustryID == response.data.ConceptCusIndustry;
            });

            SegmentFilter.forEach(function(data) {
              SegmentOptions.push({
                value: data.SegmentID,
                label: data.SegmentDesc
              });
            });

            this.SegmentOptions = SegmentOptions;

            let SubSegmentOptions = [{ value: null, label: "--Select--" }];

            var SubSegmentFilter = this.SubSegmentList.filter(function(obj) {
              return obj.SegmentID == response.data.ConceptCusSegment;
            });

            SubSegmentFilter.forEach(function(data) {
              SubSegmentOptions.push({
                value: data.SubSegmentID,
                label: data.SubSegmentDesc
              });
            });

            this.SubSegmentOptions = SubSegmentOptions;

            var NewObj = this.NewObj(response.data);
            //console.log("MappingIdea",response.data.MappingIdea);
            //console.log("ideaDataSelected",this.ideaDataSelected);
            this.TempDataEdit = response.data.MappingIdea;
            //this.ideaSelected = response.data.MappingIdea;
            //this.ideaDataSelected = response.data.MappingIdea;
            //this.$emit('updideaListSelected-updatedatedata',response.data.MappingIdea)
            //console.log("this.ideaSelected",this.ideaSelected);
            this.marketAttractiveness = response.data.MarketAttractiveness;
            this.projectRelatedness = response.data.ProjectRelatedness;

            this.CreateUser = NewObj.CreateByUser;
            this.Creator = NewObj.ConceptCreator;
            this.CreateDate = NewObj.ConceptCreateDate.substring(
              0,
              10
            ).replaceAll("-", "/");
            this.Email = NewObj.ConceptCreatorEmail;
            this.CreatorBU = NewObj.ConceptCreatorBU;

            this.LoadFilesByID(NewObj);
          }
        })
        .finally(() => {
          this.viewmode === "view" && this.SetViewMode(true);
          //console.log("marketAttractiveness",this.marketAttractiveness);
          //console.log("projectRelatedness",this.projectRelatedness);

        });
    },

    getBadge(status) {
      switch (status) {
        case "Approve":
          return "success";
        default:
          "primary";
      }
    },

    LoadIndustry() {
      axios.get(store.getters.URL + "/api/MasterIndustry/getall").then(res => {
        let Options = [{ value: null, label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            Options.push({
              value: data.IndustryID,
              label: data.IndustryDesc
            });
          });
        }
        this.IndustryOptions = Options;
      });
    },
    LoadSegment() {
      axios.get(store.getters.URL + "/api/MasterSegment/getall").then(res => {
        //console.log("MasterSegment",res);
        if (res !== null) {
          this.SegmentList = res.data;
        }
      });
    },
    IndustryOnChange(event) {
      let Options = [{ value: null, label: "--Select--" }];

      if (event !== null) {
        var Filter = this.SegmentList.filter(function(obj) {
          return obj.IndustryID == event.value;
        });

        Filter.forEach(function(data) {
          Options.push({
            value: data.SegmentID,
            label: data.SegmentDesc
          });
        });
      }

      this.SegmentOptions = Options;
    },
    LoadSubSegment() {
      axios
        .get(store.getters.URL + "/api/MasterSubSegment/getall")
        .then(res => {
          //console.log("MasterSegment",res);
          if (res !== null) {
            this.SubSegmentList = res.data;
          }
        });
    },
    SegmentOnChange(event) {
      let Options = [{ value: null, label: "--Select--" }];
      if (event !== null) {
        var Filter = this.SubSegmentList.filter(function(obj) {
          return obj.SegmentID == event.value;
        });

        Filter.forEach(function(data) {
          Options.push({
            value: data.SubSegmentID,
            label: data.SubSegmentDesc
          });
        });
      }

      this.SubSegmentOptions = Options;
    },
    LoadIdea() {
      axios.get(store.getters.URL + "/api/IdeationIdea/getalldetail").then(res => {
        //console.log("IdeationIdea", res);
        if (res !== null) {
          this.ideaData = res.data;
        }
      });
    },
    GetGroup() {
      axios.get(URL_GetGroup).then(res => {
        let options = [{ value: "", label: "-- Select --" }];
        if (res !== null) {
          res.data.forEach(function(data) {
            options.push({
              value: data.GroupID,
              label: data.GroupDesc
            });
          });
        }
        this.groupOptions = options;
      });
    },
    OpenFile(filename) {
      window.open(URL_OpenFile + "?filename=" + filename);
    },

    LoadFilesByID(Obj) {
      //console.log("LoadFilesByID", Obj);
      var fileExists = [];
      axios
        .get(URL_GetFilesByDoc, {
          params: {
            DocumentID: Obj.ConceptNo,
            Path: store.getters.URL
          }
        })
        .then(response => {
          if (response !== null) {
            //console.log("LoadFilesByID", response.data);
            Obj.fileExists = response.data;
          }
        })
        .finally(() => {
          this.DataObj = Obj;
        });
    },

    GetMasterScoreDetail(group) {
      let result = [];
      axios
        .get(store.getters.URL + "/api/MasterScoreDetail/getbygroup", {
          params: {
            group: group
          }
        })
        .then(response => {
          //console.log("GetMasterScoreDetail",response);

          if (group === "Market") {
            this.marketAttractiveness = response.data;
          } else if (group === "Project") {
            this.projectRelatedness = response.data;
          }
        })
        .catch(error => {
          //console.log("error", error);
        });
    },


  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("ConceptModal-updated", v);
      }
    },
    ideaSelected: {
      get() {
        return this.TempDataEdit.length === 0
          ? this.ideaDataSelected
          : this.TempDataEdit;
      },
      set(v) {
        this.$emit("ideaListSelected-updated", v);
      }
    },
    ConceptDataByIndustry() {
      return [
        {
          label: "Industry",
          backgroundColor: "#EF5350",
          data: [55, 20, 12, 39, 75]
        }
      ];
    },
    totalMarketAttractiveness() {
      return this.marketAttractiveness.reduce((total, item) => {
        return total + item.ScoreSelect * item.ScoreWeight;
      }, 0);
    },
    totalProjectRelatedness() {
      return this.projectRelatedness.reduce((total, item) => {
        return total + item.ScoreSelect * item.ScoreWeight;
      }, 0);
    },
    userRole: function () {
      return this.userprofile.Role;
    },
    ConceptObject: {
      get() {
        return this.item;
      },
      set(v) {
        this.$emit("ConceptObject-updated", v);
      }
    },
  },
  watch: {
    totalMarketAttractiveness: function(newVal, oldVal) {
      // watch it
      if (newVal !== null) {
        this.chartSeries = [
          {
            name: "Concept",
            data: [[newVal, this.totalProjectRelatedness]]
          }
        ];
      }
    },
    totalProjectRelatedness: function(newVal, oldVal) {
      // watch it
      if (newVal !== null) {
        this.chartSeries = [
          {
            name: "Concept",
            data: [[this.totalMarketAttractiveness, newVal]]
          }
        ];
      }
    },
    item: function(newVal, oldVal) {
      // watch it
      //console.log('item', item)
      //console.log("Prop changed: ", newVal, " | was: ", oldVal);
      if (newVal !== null) {
        this.LoadById(newVal.ConceptNo);
        this.conceptNo = newVal.ConceptNo;
        this.conceptProcessID = newVal.ConceptProcessID;
        //this.viewmode === "view" && this.SetViewMode(true);
      }
    },
    ideaSelected: function(newVal, oldVal) {
      // watch it
      //console.log('item', item)
      //console.log("ideaSelected changed: ", newVal, " | was: ", oldVal);

      if (newVal !== null) {
        if (newVal.length > 0) {

          let currentIndexAll = this.ideaData.findIndex(
            t => t.IdeaNo == newVal[0].IdeaNo
          );

          if (currentIndexAll === -1) {
            newVal[0].IsSelected = true;
            this.ideaData.push(newVal[0])
          } 

          //console.log('newVal[0]', newVal[0]);
          this.DataObj.ConceptIdea = newVal[0].IdeaDetail;
          this.DataObj.ConceptToSolve = newVal[0].IdeaToSolve;
          this.DataObj.ConceptWhom = newVal[0].IdeaWhom;
          
          var IdeaIndustryValue = null;
          if(newVal[0].IdeaIndustry !== null && newVal[0].IdeaIndustry !== undefined){            
            IdeaIndustryValue = newVal[0].IdeaIndustry;
            if(newVal[0].IdeaIndustry.value !== undefined){
              IdeaIndustryValue = newVal[0].IdeaIndustry.value;
            }
          }

          var IdeaSegmentValue = null;
          if(newVal[0].IdeaSegment !== null && newVal[0].IdeaSegment !== undefined){            
            IdeaSegmentValue = newVal[0].IdeaSegment;
            if(newVal[0].IdeaSegment.value !== undefined){
              IdeaSegmentValue = newVal[0].IdeaSegment.value;
            }
          }

          let SegmentOptions = [{ value: null, label: "--Select--" }];

            var SegmentFilter = this.SegmentList.filter(function(obj) {
              return obj.SegmentID == IdeaSegmentValue;
            });

            SegmentFilter.forEach(function(data) {
              SegmentOptions.push({
                value: data.SegmentID,
                label: data.SegmentDesc
              });
            });

            this.SegmentOptions = SegmentOptions;

          var IdeaGroupValue = null;
          if(newVal[0].IdeaGroup !== null && newVal[0].IdeaGroup !== undefined){            
            IdeaGroupValue = newVal[0].IdeaGroup;
            if(newVal[0].IdeaGroup.value !== undefined){
              IdeaGroupValue = newVal[0].IdeaGroup.value;
            }
          }

          //console.log("IdeaGroupValue",IdeaGroupValue);
          //console.log("IdeaIndustryValue",IdeaIndustryValue);
          //console.log("IdeaSegmentValue",IdeaSegmentValue);

          if(newVal[0].IdeaEventFlag){
            this.IdeaGroup = (this.groupOptions[this.groupOptions.findIndex(x => x.value === IdeaGroupValue)]);
            this.IdeaIndustry = (this.IndustryOptions[this.IndustryOptions.findIndex(x => x.value === IdeaIndustryValue)]);
            this.IdeaEventDate = newVal[0].IdeaEventDate.toString();
            this.IdeaSegment = (this.SegmentOptions[this.SegmentOptions.findIndex(x => x.value === IdeaSegmentValue)]);
          }

        }
      }
    },
  }
};
</script>


<style scoped>
.ViewerPage{
  padding: 50px 50px 50px 50px !important;
}
</style>